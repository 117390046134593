import { Box, Button, Card, CardBody, Checkbox, Container, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Heading, Image, Input, Radio, RadioGroup, Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import logo from "../../assets/images/logo.jpg" 
import { registerPatient } from '../../service/ConsultancyFormService'
import { requestHasError, requestOK } from '../../util/requestUtil'
import Sign from './Sign'
import TermsModal from './modal/TermsModal'

export default function ConsultancyForm() {


  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [sport, setSport] = useState("");
  const [gp, setGp] = useState("");
	const [medicationCheckBox, setMedicationCheckBox] = useState(false);
  const [medication, setMedication] = useState("");
  const [reasonVisit, setReasonVist] = useState("");
  const [currentMedications, setCurrentMedications] = useState("");
  const [sign, setSign] = useState("");
  const [guardSign, setGuardSign] = useState("");
	const [sex, setSex] = useState('M');
	const [contraindications, setContraindications] = useState([]);
	const [formErrors, setFormErrors] = useState([])
	const [terms1, setTerms1] = useState(false)
	const [terms2, setTerms2] = useState(false)
	const [underAge, setUnderAge] = useState(false)
	const [showModal, setShowModal] = useState(false)

	const patientSignRef = useRef();
	const guardSignRef = useRef();



	const [sendingForm, setSendingForm] = useState(false);

	const toastIdRef = useRef()

	const toast = useToast();


	const handleContraindicationsChange = (e) => {
		const contraindication = contraindications.find(f => f === e.target.value);
		if (!contraindication) {
      setContraindications(prev => [...prev, e.target.value]);
		} else {
			setContraindications((prev) => prev.filter((i) => i !== e.target.value));
		}
	}


	const createNewPatient = async () => {
		if (!checkIfAgreeWithTerms()) {
			toast({ title: 'Agree to the terms', description: "Please agree to the terms and conditions.",	status: 'error',	duration: 4000, isClosable: true })
			return;
		}
		setSendingForm(true)
		toastIdRef.current = toast({ title: 'Sending form', description: "Sending the consultancy form", status: 'loading',	isClosable: false, colorScheme: 'brand'})
		const data = {name, address, dob, phone_number: contactNumber, sport, gp, medication, reason_visit: reasonVisit, current_medications: currentMedications, sign, sex, contraindications, guard_sign: guardSign};
		const res = await registerPatient(data);
		if (requestHasError(res)) {
			setFormErrors(res.response.data)
			toast({ title: 'Complete form', description: "Please complete all required fields.",	status: 'error',	duration: 5000, isClosable: true })
		} 

		toast.close(toastIdRef.current)
		setSendingForm(false)

		if (requestOK(res)) {
			toast({ title: 'Form sent', description: "Form sent successfully",	status: 'success',	duration: 5000, isClosable: true })
			clearForm()
		}
	}

	const checkIfAgreeWithTerms = () => {
		return terms1 && terms2;
	}

	const clearForm =  () => {
		setName("")
		setAddress("")
		setDob("")
		setContactNumber("");
		setSport("");
		setGp("");
		setMedicationCheckBox(false);
		setMedication("");
		setReasonVist("");
		setCurrentMedications("");
		setSign("");
		setSex('M');
		setContraindications([]);
		setFormErrors([])
		setTerms1(false);
		setTerms2(false);
		setUnderAge(false);
		patientSignRef.current.resetCanvas();
		guardSignRef.current.resetCanvas();
	}
	
  return (
	<Container maxW={{ md: "90%", sm: "100%" }} p={2}>
		<Container maxW="100%" mt={10}>
			<TermsModal isOpen={showModal} onCloseModal={() => setShowModal(false)} checkTerms={() => setTerms1(!terms1)} terms={terms1}
				acceptTerms={() => {
					setTerms1(true);
					setShowModal(false);
				}}
			></TermsModal>
			
			<HStack direction='row' mb={5}>
				<Image src={logo} alt='Emma Bexon Therapy' width={100}/>

				<Heading as='h4' size='md' color="brand.500">
					Sports massage and training consultancy form
				</Heading>
			</HStack>

			<Text mb={5}>Please fill out all the information below. Fields marked with (*) are required.</Text>
			
			<Grid templateColumns={{ lg: 'repeat(2, 1fr)', md: 'repeat(1, 1fr)'}} gap={2}>
					<GridItem  w='100%'>
							<FormControl isInvalid={formErrors.name && true}>
									<FormLabel>Name*</FormLabel>
									<Input type='text' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)}/>
									<FormErrorMessage>{formErrors.name}</FormErrorMessage>
							</FormControl>
					</GridItem>
					<GridItem w='100%'>
							<FormControl>
									<FormLabel>Sex*</FormLabel>
									<RadioGroup value={sex} onChange={setSex}>
											<Stack direction='row'>
													<Radio value='M' colorScheme='brand'>Male</Radio>
													<Radio value='F' colorScheme='brand'>Female</Radio>
											</Stack>
									</RadioGroup>
							</FormControl>
					</GridItem>
					<GridItem  w='100%'>
							<FormControl isInvalid={formErrors.address && true}>
									<FormLabel>Address*</FormLabel>
									<Input type='text' placeholder='Address' value={address} onChange={(e) => setAddress(e.target.value)}/>
									<FormErrorMessage>{formErrors.address}</FormErrorMessage>
							</FormControl>
					</GridItem>
					<GridItem w='100%'>
							<FormControl isInvalid={formErrors.dob && true}>
									<FormLabel>DOB*</FormLabel>
									<Input type='text' placeholder='DOB' value={dob} onChange={(e) => setDob(e.target.value)}/>
									<FormErrorMessage>{formErrors.dob}</FormErrorMessage>
							</FormControl>
					</GridItem>
					<GridItem w='100%'>
							<FormControl isInvalid={formErrors.phone_number && true}>
								<FormLabel>Contact Number*</FormLabel>
								<Input type='text' placeholder='Contact Number' value={contactNumber} onChange={(e) => setContactNumber(e.target.value)}/>
								<FormErrorMessage>{formErrors.phone_number}</FormErrorMessage>
							</FormControl>
					</GridItem>
					<GridItem w='100%'>
							<FormControl>
									<FormLabel>Sport/ fitness (if any)</FormLabel>
									<Input type='text' placeholder='Sport / fitness' value={sport} onChange={(e) => setSport(e.target.value)}/>
							</FormControl>
					</GridItem>
					<GridItem w='100%'>
							<FormControl>
									<FormLabel>GP</FormLabel>
									<Input type='text' placeholder='GP' value={gp} onChange={(e) => setGp(e.target.value)}/>
							</FormControl>
					</GridItem>
					<GridItem w='100%'>
							
							<FormControl>
									<FormLabel> <Checkbox colorScheme='brand' defaultChecked={medicationCheckBox} direction='row' onChange={(e) => setMedicationCheckBox(!medicationCheckBox)}>Medication</Checkbox></FormLabel>
									<Input type='text' placeholder='What type of medication' value={medication} onChange={(e) => setMedication(e.target.value)} disabled={!medicationCheckBox}/>
							</FormControl>
					</GridItem>
			</Grid>
			<Text mb='8px' mt='8px'>Reason for visit*</Text>
			<Textarea
					isInvalid={formErrors.reason_visit && true}
					placeholder='Reason for visit'
					size='md'
					value={reasonVisit} onChange={(e) => setReasonVist(e.target.value)}
			/>
			<Text fontSize="sm" color="red">{formErrors.reason_visit}</Text>
			
			
			<Card w="100%" mt="20px" mb="15px">
				<CardBody>
					<Text size="md" mb="10px" >Check any contraindications that apply to you:</Text>
					<Grid templateColumns={{ xl: 'repeat(5, 1fr)', lg: 'repeat(4, 1fr)', md: 'repeat(3, 1fr)', sm: 'repeat(2, 1fr)'}} gap={2}>
						
						<GridItem  w='100%'>

						<Checkbox colorScheme='brand' value="1" onChange={handleContraindicationsChange}>
								Acute Trauma
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="2" onChange={handleContraindicationsChange}>
								Haematoma / bruising
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="3" onChange={handleContraindicationsChange}>
								Ruptures
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="4" onChange={handleContraindicationsChange}>
								Fractures
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="5" onChange={handleContraindicationsChange}>
								Hypertension
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="6" onChange={handleContraindicationsChange}>
								Angina / Heart conditions
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="7" onChange={handleContraindicationsChange}>
								Infections
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="8" onChange={handleContraindicationsChange}>
								Arthritis
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="9" onChange={handleContraindicationsChange}>
								Pregnant
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="10" onChange={handleContraindicationsChange}>
								HVI / Aids
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="11" onChange={handleContraindicationsChange}>
								Bursitis
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="12" onChange={handleContraindicationsChange}>
								Skin conditions
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="13" onChange={handleContraindicationsChange}>
								Diabetes
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="14" onChange={handleContraindicationsChange}>
								Epilepsy
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="15" onChange={handleContraindicationsChange}>
								Allergies
						</Checkbox>
						</GridItem>
						<GridItem  w='100%'>
						<Checkbox colorScheme='brand' value="16" onChange={handleContraindicationsChange}>
								Cancer
						</Checkbox>
						</GridItem>
					</Grid>
				</CardBody>
			</Card>

			<Card >
				<CardBody>
					<Text mt={2} mb={2} color={'brand.500'} fontWeight={600}>Please check to agree to the terms and conditions and consent to treatment.</Text>
					<Checkbox isChecked={terms2} onChange={(e) => setTerms2(!terms2)} colorScheme='brand' textDecoration={terms2 && 'line-through'}>If I cancel within 6 hours of my schedule appointment a fee will be charged.</Checkbox>
					<Checkbox mb={2}  isChecked={terms1} onChange={(e) => setShowModal(true)} colorScheme='brand' textDecoration={terms1 && 'line-through'}>I acknowledge that I have read and understood this waiver and agree to be bound by its terms.</Checkbox>

				</CardBody>
			</Card>

			<Box textAlign="left" maxW={500} mt="20px">
				<Sign setSign={(value) => setSign(value)} ref={patientSignRef} title="Sign the form in the box bellow:" buttonText="Tap to sign form"></Sign>
				<Text fontSize="sm" color="red">{formErrors.sign}</Text>
			</Box>

	
			<Box mt={5}>
				<Checkbox mb={2} isChecked={underAge} onChange={(e) => setUnderAge(!underAge)} colorScheme='brand'>I am under 18 years old.</Checkbox>
				{
					underAge && 
					(<Box textAlign="left" maxW={500}>
						<Sign setSign={(value) => setGuardSign(value)} ref={guardSignRef} title="Parent/Guardian Signature" buttonText="Tap here to sign (Guardian/Parent)"></Sign>
					</Box>)
				}
			</Box>

		
			<Box mt={6} mb={12}>
				<Button colorScheme='brand' variant='solid' onClick={createNewPatient} isLoading={sendingForm}>
					Send consultancy form
				</Button>
			</Box>
		</Container>
    </Container >

  );
}
