import { Button, Checkbox, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React from 'react'

export default function TermsModal({isOpen, onCloseModal, checkTerms, terms, acceptTerms}) {
  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Terms and conditions</ModalHeader>
        <ModalCloseButton />
        <ModalBody >

          <Text fontWeight="bold" fontSize={14} mb={1}> Massage therapy </Text>

          <Text fontSize={13} mb={2} textAlign={'justify'}> By signing this waiver, I acknowledge that I am receiving massage therapy services from Emma Bexon Therapy at my own risk and agree to release and discharge the therapist from any and all liability for injuries or damages I may sustain during or after the massage. I understand that massage therapy involves physical manipulation of the body and that there are inherent risks involved, including but not limited to muscle soreness, temporary bruising, and aggravation of pre-existing conditions. I confirm that I have disclosed all relevant medical information to the therapist and am responsible for communicating any discomfort or concerns during the massage.</Text>

          <Text fontSize={14} mb={1} fontWeight="bold" > Personal training </Text>

          <Text fontSize={13} mb={2} textAlign={'justify'}>By signing this waiver, I acknowledge that I am participating in activities at Emma Bexon Training at my own risk and agree to release and discharge the gym and its owners from any and all liability for injuries or damages I may sustain while on the premises. I understand that physical activity involves inherent risks, and I am responsible for my own safety and well-being.
            I acknowledge that these risks cannot be completely eliminated, even with proper instruction and equipment use.</Text>

          <Text fontSize={13} mb={2} textAlign={'justify'}>I agree to indemnify and hold harmless Emma Bexon Training/Therapy, from any and all claims, demands, actions, or expenses arising out of my participation in activities at the gym.</Text>

          <Text fontSize={13} mb={3} textAlign={'justify'}>I confirm that I am in good health and have no medical conditions that would prevent me from safely exercising or receiving treatment. </Text>
        </ModalBody>
        <ModalFooter>
          <Button size={'sm'} fontSize='sm' colorScheme='brand' mr={3} onClick={acceptTerms}>
            Accept terms
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
